*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}

html {
  scroll-behavior: smooth;
}
html,
body {
  height: 100%;
}
body {
  background: #fafafa !important;
  overflow-x: hidden;
  line-height: 1.5;
  font-size: 16px;
  font-family: apple-system, BlinkMacSystemFon, 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
input,
button,
textarea,
select {
  font: inherit;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
#app {
  isolation: isolate;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
}
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ql-container.ql-snow {
  border-radius: 0 0 12px 12px;
  background-color: rgb(240, 241, 245);
  border: none !important;
}

.ql-toolbar.ql-snow {
  border-radius: 12px 12px 0 0;
  background-color: rgb(240, 241, 245);
  border: none !important;
  border-bottom: 1px solid rgb(226, 232, 240) !important;
}
